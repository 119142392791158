.statsByPlayerTable {
  min-width: 100%;
  max-width: 340px;
  flex-direction: column;
  overflow: scroll;
  //height: 800px; // TODO

  .table-container {
    width: 100%;
    overflow: auto;
    position: relative;
    flex-direction: column;
  }

  .table {
    border-collapse: collapse;
    min-width: 50%;
  }

  th {
    padding: 0px;
    height: 100%;
  }
  //td {
  //  padding: 1px;
  //  height: 100%;
  //}

  .sticky-header {
    vertical-align: bottom;
    min-width: 114px;
    word-break: break-word;
    position: sticky;
    top: 0;
    z-index: 2; /* Ensures headers appear on top */
    padding-bottom: 10px;
    background-color: #b6ced1;
    &.corner {
      background-color: initial;
      vertical-align: bottom;
      padding-bottom: 16px;
      .wrapper {
        flex-direction: column;
        align-items: flex-end;
        .visibility {
          justify-content: center;
        }
      }
    }
    .matchRank {
      font-size: large;
      color: white;
      background-color: #95b1b4;
      padding: 3px;
      border-radius: 66px;
      width: 40px;
      height: 40px;
    }
    &.isMatch {
      min-width: 90px;
    }
    .total {
      justify-content: center;
      align-items: center;
      color: #53797d;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      font-size:0.8em;
    }
  }

  .sticky-label {
    position: sticky;
    left: 0;
    background-color: #d7e4e6;
    z-index: 1;
    font-size: medium;
    border-bottom: 4px solid #c9dcdf;
    border-radius: 60px 0px 0px 60px;
    //min-width: 340px;
    .label-wrapper {
      align-items: center;

      .matchRank {
        color: white;
        background-color: #95b1b4;
        font-size: large;
        margin-right: 5px;
        border-radius: 66px;
        width: 40px;
        height: 40px;
        background-image: linear-gradient(to bottom, #87c9f7, #2c7d8a);
        border: 2px solid white;
      }
      .blastButtonWrapper {
        padding-right: 3px;
        padding-left: 24px;
        .blastButton {
          height: 34px;
          width: 79px;
          min-width: 30px;
          padding: 0px;
          border: none;
          background-position: right;
          &.left {
            padding: 0px;
          }
        }
      }

      .playerScore {
        flex-direction: row;
        border-radius: 2px;
        padding: 0px 12px;
        font-size: large;
        align-items: center;
        margin-right: 5px;
        cursor: pointer;

      }
    }
  }
  .playerScore {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    border-bottom: 2px solid #d7e4e6;
  ;

    &.isRound {
      .score {
        width: 70%;
        font-size: 1.3em;
        font-weight: 600;
        font-family: "Montserrat";
        padding-top: 6px;
        border-radius: 0px;
        text-align: center;
        line-height: 40px;
      }
    }

    &.isMatch {
      .score {
        border-radius: 10px !important;
        width: 66%;
        border: 2px solid white;
        font-family: "Montserrat";
        background: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.41) 29%, rgba(255, 255, 255, 0.92) 75%, rgba(255, 255, 255, 0.05) 100%);
        color: #444847;
        font-size: 1.4em;
        font-weight: 600;
        box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.29);
      }
    }
      .playerBadge {
        font-size: x-large;
        color: #727373; // var(--color-border-primary);
        align-items: center;
        &.guestName {
          text-align: center;
          padding: 3px 6px;
          border-radius: 8px;
          font-size: 1.2em;
          font-family: Montserrat;
          font-weight: 600;
          color: #466265;
        }
        font-weight: normal;
      }
    .score {
      background-color: var(--color-bg-score-progress-bar);
      border-radius: 4px;
      min-width: 40px;
      padding: 1px 2px;
      color: var(--color-txt-score-summary);
      height: 100%;
      justify-content: center;
      font-size: 1.1em;
      border-radius: 24px;
      padding: 8px;
      font-family: "Montserrat";
      font-weight: 600;
    }
    .counters {
      margin-top: 4px;
      div {
        width: 30px;
      }
      input {
        max-width: 30px;
        text-align: center;
        color: black;
        padding: 1px;
        font-size: 0.7em;
      }
    }
  }
  .trackBadge {
    padding-left: 10px;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .currTrackTitle {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      margin-right: 10px;
      flex: 1;
      text-transform: lowercase;
      font-size: large;

      button {
        text-transform: inherit;
        color: #385e63;
        background-color: unset;
        font-family: Montserrat;
        font-size: 0.8em;
        text-align: center;
        padding: 0;
        max-width: -moz-fit-content;
        max-width: fit-content;
        font-weight: 700;
        padding-top:12px;
        &:hover {
          border: none;
        }

      }
      .artist {
        font-family: Montserrat;
        font-size: 0.75em;
        color: #7cadb2;
        font-weight:500;
        padding-bottom:8px
      }

      &.disabled {
        cursor: default;
      }
    }
  }
  .trackInfo {
    z-index: 1;
    min-width: 94px;
    font-size: small;
    color: var(--color-txt-tile);
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    padding-top: 0px;
    background-color: #d7e4e6;
    border-radius: 4px;
    .score {
      padding: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: large;

      .points {
        color: #000;
      }
      .pointInfo {
        min-width: 140px;
      }
    }
    span {
      color: #b8b4b4; // var(--color-border-primary);
    }
  }

}
