
.interactionContainer {
  flex: 5 1 auto;
  position: relative;
  color: var(--color-txt-lyrics);
  padding: 9px;
  font-size: var(--size-txt-lyrics);
  overflow-y:auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  outline: none;

  &.notFirst {
    border-left: 3px dotted #ffffff66;
  }
  .bumper {
    background-color: $color-bg-lyrics-bumper;
    width: 80px;
    height: 6px;
    cursor: inherit;
    padding: 0px;
    margin-right: auto;
    margin-left: auto;

    &.selected  {
      -webkit-box-shadow: 0px 0px 6px $color-bg-lyrics-word-playing;
      -moz-box-shadow: 0px 0px 6px $color-bg-lyrics-word-playing;
      box-shadow: 0px 0px 6px $color-bg-lyrics-word-playing;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .label {
      background-color: $color-bg-lyrics-section;
      margin:10px;
      padding:4px;
      display: block;
      width: max-content;
      border-radius: 6px;
      cursor: pointer;
      font-size: var(--size-txt-section);
      min-width: .75em;
      span {
        color: $color-txt-lyrics-section;
        padding: 1px;
      }
      &.mini {
        background-color: $color-bg-lyrics-section-mini;
        margin: 0;
        border-radius: 6px;
        width: 1px;
        span {
          display: none;
        }
        //&.viz {
        //  display: none;
        //}
      }
      &.viz {
        position: absolute;
        margin: 0px;
        z-index: inherit;

        &.selected  {
          opacity: 1.0;
          z-index: 98000;
        }
      }

      .text {

      }
      .timestamp {

      }
    }

    .lines {
      .line {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        flex-wrap: wrap;

        .label {
          .text {

          }
          .timestamp {

          }
          &.isPlayhead {

          }
          &.isTarget {

          }
        }
        .words {
          .word {
            .label {
              .text {

              }
              .timestamp {

              }
              &.isPlayhead {

              }
              &.isTarget {

              }
              &.spellingTarget {

              }
            }
          }
        }

        &.viz {
          position: absolute;
          /*opacity: 0.4;*/
          z-index: inherit;

          &.selected  {
            opacity: 1.0;
            z-index: 99000;
          }
        }
        &.read {
          justify-content: flex-start;
        }
        &.play.selected {
          background-color: rgba(255,255,255,.2)
        }
      }
    }
    &.read {
      align-items: flex-start;
    }
    &.selected {
      .label {
        background-color: $color-bg-lyrics-section-selected !important;
      }
      .lines {
        background-color: rgba(255, 255, 255, 0.1);
        width: 100%;
        border-radius: 4px;
      }
    }
    @keyframes blink {
      100% {
        background-color: red;
      }
      0% {
        background-color: white;
      }
    }
    &.sectionAnimation {
      .label {background-color: red;
       animation: blink 0.5s ease-in-out 6;
    }
  }
    &.isPlayhead {

    }
    &.isTarget {

    }
  }
}